import React from 'react'

const Twitter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#FF9F1C" />
    <path
      d="M16.4639 6.18636C15.9517 6.40734 15.3942 6.56638 14.82 6.62832C15.4161 6.27414 15.8626 5.71459 16.0755 5.05466C15.5161 5.38745 14.9033 5.62082 14.2642 5.74439C13.997 5.45883 13.674 5.23134 13.3151 5.0761C12.9562 4.92086 12.5692 4.84118 12.1782 4.84205C10.5962 4.84205 9.32387 6.12441 9.32387 7.69808C9.32387 7.91906 9.35066 8.14004 9.39418 8.35265C7.02532 8.22877 4.9126 7.09707 3.50802 5.36437C3.25209 5.8015 3.11798 6.29923 3.11963 6.80578C3.11963 7.79685 3.62354 8.67073 4.39195 9.18468C3.93911 9.16685 3.49688 9.04238 3.10121 8.8214V8.85656C3.10121 10.2444 4.08224 11.3945 5.38972 11.659C5.14423 11.7228 4.89168 11.7554 4.63804 11.7561C4.45222 11.7561 4.27644 11.7377 4.09898 11.7126C4.46059 12.8443 5.5136 13.6663 6.76751 13.6931C5.78648 14.4615 4.55769 14.9135 3.22342 14.9135C2.98403 14.9135 2.76304 14.9051 2.53369 14.8783C3.79932 15.6903 5.30099 16.159 6.91818 16.159C12.1682 16.159 15.0409 11.8097 15.0409 8.03457C15.0409 7.91069 15.0409 7.7868 15.0326 7.66292C15.5884 7.25611 16.0755 6.7522 16.4639 6.18636Z"
      fill="#FDFFFC"
    />
  </svg>
)

export { Twitter }
