import React from 'react'

const Facebook: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#FF9F1C" />
    <path
      d="M14.9288 4.64166H5.07164C4.83458 4.64166 4.64307 4.83318 4.64307 5.07023V14.9274C4.64307 15.1644 4.83458 15.356 5.07164 15.356H14.9288C15.1658 15.356 15.3574 15.1644 15.3574 14.9274V5.07023C15.3574 4.83318 15.1658 4.64166 14.9288 4.64166ZM14.5002 14.4988H12.0346V11.2068H13.4274L13.6364 9.59032H12.0346V8.55773C12.0346 8.08898 12.1645 7.77023 12.8355 7.77023H13.6913V6.32381C13.5426 6.30372 13.035 6.25952 12.4431 6.25952C11.2082 6.25952 10.3632 7.01354 10.3632 8.39702V9.58898H8.96762V11.2055H10.3645V14.4988H5.50021V5.49881H14.5002V14.4988Z"
      fill="#FDFFFC"
    />
  </svg>
)

export { Facebook }
