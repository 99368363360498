import React from 'react'

const Email: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#FF9F1C" />
    <g clipPath="url(#clip0)">
      <path
        d="M15.5714 5.28326H4.42857C4.19152 5.28326 4 5.47478 4 5.71184V14.2833C4 14.5203 4.19152 14.7118 4.42857 14.7118H15.5714C15.8085 14.7118 16 14.5203 16 14.2833V5.71184C16 5.47478 15.8085 5.28326 15.5714 5.28326ZM15.0357 6.76719V13.7476H4.96429V6.76719L4.59464 6.47925L5.12098 5.80291L5.6942 6.24889H14.3071L14.8804 5.80291L15.4067 6.47925L15.0357 6.76719ZM14.3071 6.24755L10 9.59576L5.69286 6.24755L5.11964 5.80157L4.5933 6.47791L4.96295 6.76585L9.53795 10.323C9.66952 10.4252 9.83138 10.4807 9.99799 10.4807C10.1646 10.4807 10.3265 10.4252 10.458 10.323L15.0357 6.76719L15.4054 6.47925L14.879 5.80291L14.3071 6.24755Z"
        fill="#FDFFFC"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
)

export { Email }
